<template>
  <div class="flex flex-col min-h-screen bg-gray-100">
    <!-- Navigation Bar -->
    <nav class="bg-indigo-800">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex items-center justify-between h-16">
          <!-- Brand -->
          <div class="flex-shrink-0 flex items-center">
            <router-link
              to="/"
              class="brand-link flex items-center hover:text-indigo-200 transition duration-300 ease-in-out"
            >
              <img src="/favicon.ico" alt="Logo" class="mr-2 h-8 w-8" />
              <span class="brand-name">Zach Egner</span>
            </router-link>
          </div>
          <!-- Navigation Links -->
          <div class="hidden md:block">
            <div class="ml-10 flex items-baseline space-x-4">
              <router-link
                v-for="link in links"
                :key="link"
                :to="`/${link.toLowerCase()}`"
                class="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                active-class="text-white bg-indigo-700"
              >
                {{ link }}
              </router-link>
            </div>
          </div>
          <!-- Mobile Menu Button -->
          <div class="md:hidden">
            <button @click="isMobileMenuOpen = !isMobileMenuOpen" type="button" class="text-gray-300 hover:text-white focus:outline-none focus:text-white">
              <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path v-if="!isMobileMenuOpen" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"/>
                <path v-else stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <!-- Mobile Menu -->
      <div v-if="isMobileMenuOpen" class="md:hidden">
        <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          <router-link
            v-for="link in links"
            :key="link"
            :to="`/${link.toLowerCase()}`"
            class="block text-gray-300 hover:text-white px-3 py-2 rounded-md text-base font-medium"
            active-class="text-white bg-indigo-700"
          >
            {{ link }}
          </router-link>
        </div>
      </div>
    </nav>

    <!-- Main Content -->
    <main class="flex-grow">
      <div class="max-w-7xl mx-auto p-4 sm:p-6 lg:p-8">
        <router-view></router-view>
      </div>
    </main>

    <!-- Footer -->
<footer class="bg-indigo-700 text-gray-200">
  <div class="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
    <div class="flex flex-col items-center">
      <div class="mb-2">
        &copy; {{ currentYear }} — <strong>Zach Egner</strong>
      </div>
      <div class="flex space-x-4">
        <a href="mailto:egner.zach@gmail.com" aria-label="Email">
          <font-awesome-icon icon="envelope" class="footer-icon text-red-500 w-5 h-5 text-xl mr-4 flex-shrink-0" />
        </a>
        <a href="https://www.linkedin.com/in/zach-egner" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
          <font-awesome-icon :icon="['fab', 'linkedin']" class="footer-icon text-blue-500 w-5 h-5 text-xl mr-4 flex-shrink-0" />
        </a>
        <a href="https://github.com/zachegner" target="_blank" rel="noopener noreferrer" aria-label="GitHub">
          <font-awesome-icon :icon="['fab', 'github']" class="footer-icon text-gray-800 w-5 h-5 text-xl mr-4 flex-shrink-0" />
        </a>
      </div>
    </div>
  </div>
</footer>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      links: ["Home", "Contact"],
      isMobileMenuOpen: false,
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<style>
body {
  font-family: 'Roboto', sans-serif;
  background-color: #f5f5f5;
}

.brand-link {
  position: relative;
}

.brand-name {
  font-size: 1.5rem;
  font-weight: bold;
  background: linear-gradient(to right, #e6fcff, #ceeeff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.brand-link:hover .brand-name {
  transform: scale(1.1);
}

.brand-link img {
  transition: transform 0.3s ease-in-out;
}

.brand-link:hover img {
  transform: rotate(20deg);
}

.footer-icon {
  transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.footer-icon:hover {
  transform: scale(1.1);
}
</style>