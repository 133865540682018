// src/router.js
import { createRouter, createWebHistory } from "vue-router";
import AboutMe from "@/components/AboutMe.vue";
//import MyProjects from "@/components/MyProjects.vue";
import ContactMe from "@/components/ContactMe.vue";
import UnderConstruction from "@/components/UnderConstruction.vue";

const routes = [
  { path: "/", redirect: "/home" },
  { path: "/home", component: AboutMe },
  //{ path: "/projects", component: MyProjects },
  { path: "/contact", component: ContactMe },
  { path: "/under-construction", component: UnderConstruction },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
