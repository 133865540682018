<template>
  <section id="contact" class="p-12 bg-gradient-to-r from-green-50 to-blue-50">
    <!-- Section Header -->
    <div class="max-w-3xl mx-auto text-center mb-12">
      <h2 class="text-4xl font-semibold mb-4 text-blue-800">Let's Connect</h2>
      <p class="text-lg text-gray-700">
        I'm always open to discussing development work or partnership opportunities. Feel free to connect with me via email or follow me on my social media channels linked below.
      </p>
    </div>
    
    <!-- Contact Links -->
    <div class="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-6">
      <!-- Email Card -->
      <div class="bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300 p-6 flex items-center">
        <!-- Email Icon -->
        <font-awesome-icon icon="envelope" class="text-red-500 w-6 h-6 md:w-10 md:h-10 text-xl mr-4 flex-shrink-0" />
        <!-- Email Link -->
        <a href="mailto:egner.zach@gmail.com" class="text-lg lg:text-base text-gray-800 hover:text-blue-600">
          zach@zachegner.com
        </a>
      </div>
      
      <!-- LinkedIn Card -->
      <div class="bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300 p-6 flex items-center">
        <!-- LinkedIn Icon -->
        <font-awesome-icon :icon="['fab', 'linkedin']" class="text-blue-500 w-6 h-6 md:w-10 md:h-10 text-xl mr-4 flex-shrink-0" />
        <!-- LinkedIn Link -->
        <a
          href="https://www.linkedin.com/in/zach-egner"
          target="_blank"
          rel="noopener noreferrer"
          class="text-lg lg:text-base text-gray-800 hover:text-blue-600"
        >
          LinkedIn Profile
        </a>
      </div>
      
      <!-- GitHub Card -->
      <div class="bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300 p-6 flex items-start">
        <!-- GitHub Icon -->
        <font-awesome-icon :icon="['fab', 'github']" class="text-gray-800 w-6 h-6 md:w-10 md:h-10 text-xl mr-4 flex-shrink-0" />
        <!-- GitHub Links -->
        <div>
          <p class="text-lg lg:text-base text-gray-800 mb-2">
            Personal: 
            <a
              href="https://github.com/zachegner"
              target="_blank"
              rel="noopener noreferrer"
              class="text-blue-600 hover:underline"
            >
              zachegner
            </a>
          </p>
          <p class="text-lg lg:text-base text-gray-800">
            Work: 
            <a
              href="https://github.com/zachegnermh"
              target="_blank"
              rel="noopener noreferrer"
              class="text-blue-600 hover:underline"
            >
              zachegnermh
            </a>
          </p>
        </div>
      </div>
    </div>

    <!-- Contact Form Section (Visible Only on /contact Page) -->
    <div v-if="isContactPage" class="max-w-2xl mx-auto mt-12">
      <h3 class="text-2xl font-bold mb-4 text-blue-800">Send Me a Message</h3>
      <form @submit.prevent="sendEmail" class="bg-white p-6 rounded-lg shadow-lg">
        <!-- Name Field -->
        <div class="mb-4">
          <label for="name" class="block text-gray-700">Name</label>
          <input
            type="text"
            id="name"
            v-model="form.name"
            required
            class="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
            placeholder="Your Name"
          />
        </div>
        <!-- Email Field -->
        <div class="mb-4">
          <label for="email" class="block text-gray-700">Email</label>
          <input
            type="email"
            id="email"
            v-model="form.email"
            required
            class="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
            placeholder="Your Email"
          />
        </div>
        <!-- Subject Field -->
        <div class="mb-4">
          <label for="subject" class="block text-gray-700">Subject</label>
          <input
            type="text"
            id="subject"
            v-model="form.subject"
            required
            class="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
            placeholder="Subject"
          />
        </div>
        <!-- Message Field -->
        <div class="mb-4">
          <label for="message" class="block text-gray-700">Message</label>
          <textarea
            id="message"
            v-model="form.message"
            required
            rows="5"
            class="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
            placeholder="Your Message"
          ></textarea>
        </div>
        <!-- Submit Button -->
        <div class="flex items-center justify-between">
          <button
            type="submit"
            :disabled="isSubmitting"
            class="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700 transition duration-300 ease-in-out disabled:opacity-50"
          >
            {{ isSubmitting ? 'Sending...' : 'Send Message' }}
          </button>
        </div>
        <!-- Success & Error Messages -->
        <div v-if="successMessage" class="mt-4 text-green-600">
          {{ successMessage }}
        </div>
        <div v-if="errorMessage" class="mt-4 text-red-600">
          {{ errorMessage }}
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import emailjs, { EmailJSResponseStatus } from '@emailjs/browser';

export default {
  name: "ContactMe",
  setup() {
    const route = useRoute();

    // Determine if the current route is '/contact'
    const isContactPage = computed(() => route.path === '/contact');

    // Form state
    const form = ref({
      name: '',
      email: '',
      subject: '',
      message: '',
    });

    // Submission status
    const isSubmitting = ref(false);
    const successMessage = ref('');
    const errorMessage = ref('');

    // Handle form submission
    const sendEmail = async () => {
      isSubmitting.value = true;
      successMessage.value = '';
      errorMessage.value = '';

      const templateParams = {
        from_name: form.value.name,
        from_email: form.value.email,
        subject: form.value.subject,
        message: form.value.message,
      };

      try {
        const response = await emailjs.send(
          process.env.VUE_APP_EMAILJS_SERVICE_ID,
          process.env.VUE_APP_EMAILJS_TEMPLATE_ID,
          templateParams,
          process.env.VUE_APP_EMAILJS_PUBLIC_KEY
        );
        console.log('SUCCESS!', response.status, response.text);
        successMessage.value = 'Your message has been sent successfully!';
        // Clear form fields
        form.value.name = '';
        form.value.email = '';
        form.value.subject = '';
        form.value.message = '';
      } catch (err) {
        if (err instanceof EmailJSResponseStatus) {
          // Handle EmailJS specific errors
          console.error('EMAILJS FAILED...', err.status, err.text);
          errorMessage.value = 'Failed to send the message via EmailJS. Please try again later.';
          return;
        }
        // Handle other errors
        console.error('ERROR', err);
        errorMessage.value = 'An unexpected error occurred. Please try again later.';
      } finally {
        isSubmitting.value = false;
      }
    };

    return {
      isContactPage,
      form,
      isSubmitting,
      successMessage,
      errorMessage,
      sendEmail,
    };
  },
};
</script>

<style scoped>
@media (max-width: 768px) {
.font-awesome-icon {
width: 6;
height: 6;
font-size: 1rem;
}
a {
font-size: 0.8rem; /* Adjust to your desired smaller font size, generally 14px */
}
}
section {
background: linear-gradient(to right, #e6fcff, #ebf8ff);
}
h2 {
color: #2563eb;
}
p {
color: #4b5563;
}
a:hover {
color: #2563eb;
}
</style>