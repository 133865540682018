import { createApp } from 'vue'
import App from './App.vue'
import router from "./router"; // Import Vue Router
import { loadFonts } from './plugins/webfontloader'

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';

import './assets/tailwind.css';

library.add(faEnvelope, faLinkedin, faGithub);

loadFonts()

createApp(App)
  .use(router)
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app')
