<template>
  <div class="container mx-auto px-4 py-10 bg-gradient-to-r from-blue-50 to-green-50">
    <section id="about-me">
      <div class="flex flex-col md:flex-row items-center md:items-start">

        <!-- Profile Image and ContactMe Component -->
        <div class="md:w-2/3 flex flex-col items-center mb-8 md:mb-0 space-y-6">
          <img
            src="@/assets/me.jpeg"
            alt="Profile Image"
            class="w-48 h-48 rounded-full shadow-xl object-cover ring-4 ring-white"
          />
          <ContactMe class="mt-6"/>
        </div>

        <!-- About Card -->
        <div class="md:w-1/3 md:pl-10 mt-8 md:mt-0">
          <div class="bg-white shadow-lg rounded-xl p-8 space-y-4 hover:shadow-2xl transition-shadow duration-300">
            <h3 class="text-2xl font-bold mb-2 text-blue-800">About Zach Egner</h3>
            <p class="text-justify text-sm text-gray-700 mb-2">
              Hi, I'm Zach Egner—a tech enthusiast, creative problem-solver, and someone who finds joy in building meaningful connections. Based in the heart of New England, I channel my passion for technology into projects that bridge innovation with everyday life.
            </p>
            <p class="text-justify text-sm text-gray-700">
              Whether it's developing interactive web applications, crafting seamless user experiences, or exploring the intersections of AI and automation, I thrive on turning ideas into tangible solutions.
            </p>
          </div>
        </div>

      </div>
    </section>
  </div>
</template>

<script>
import ContactMe from './ContactMe.vue';
export default {
  name: "AboutMe",
  components: { ContactMe },
};
</script>

<style scoped>
/* Basic styles for customization */
.container {
  background: linear-gradient(to right, #ebf8ff, #e6f9ff);
}
h3 {
  color: #2563eb;
}
p {
  color: #4b5563;
}
</style>